import { TransactionType } from "@/utils/api-client";

export default {
  [TransactionType.DEPOSIT]: "Пополнение средств",
  [TransactionType.INVESTMENT_REFUND]: "Возврат инвестиции",
  [TransactionType.INVESTMENT_CANCEL]: "Отмена инвестиции",
  [TransactionType.LOAN]: "Выдача займа",
  [TransactionType.DIVIDEND_PRINCIPAL]: "Возврат основного долга",
  [TransactionType.DIVIDEND_INTEREST]: "Возврат по процентной ставке",
  [TransactionType.DIVIDEND_PENALTY]: "Штраф за просроченный платеж",
  [TransactionType.DIVIDEND_COMPENSATION]: "Компенсация за досрочное погашение займа",
  [TransactionType.GUARANTOR_INTEREST]: "Возврат по процентной ставке (гарантия выкупа)",
  [TransactionType.GUARANTOR_COMPENSATION]: "Компенсация за досрочное погашение займа (гарантия выкупа)",
  [TransactionType.GUARANTOR_PENALTY]: "Штраф за просроченный платеж (гарантия выкупа)",
  [TransactionType.SALE]: "Продажа инвестиции",
  [TransactionType.WITHDRAW]: "Вывод средств",
  [TransactionType.INVESTMENT]: "Инвестиция",
  [TransactionType.REPAYMENT_PRINCIPAL]: "Возврат основного долга",
  [TransactionType.REPAYMENT_INTEREST]: "Возврат по процентной ставке",
  [TransactionType.REPAYMENT_PENALTY]: "Штраф за просроченный платеж",
  [TransactionType.REPAYMENT_COMPENSATION]: "Компенсация за досрочное погашение займа",
  [TransactionType.PURCHASE]: "Покупка инвестиции",
  [TransactionType.PURCHASE_FEE]: "Комиссия за покупку инвестиции",
  [TransactionType.SALE_FEE]: "Комиссия за продажу инвестиции",
  [TransactionType.LOAN_FEE]: "Комиссия за публикацию проекта",
  [TransactionType.DIVIDEND_INTEREST_FEE]: "Комиссия с возврата по процентной ставке",
  [TransactionType.DIVIDEND_PENALTY_FEE]: "Комиссия с штрафа за просроченый платеж",
  [TransactionType.DIVIDEND_COMPENSATION_FEE]: "Комиссия с компенсации за досрочное погашение займа",
  [TransactionType.GUARANTOR_INTEREST_FEE]: "Комиссия с возврата по процентной ставке (гарантия выкупа)",
  [TransactionType.GUARANTOR_PENALTY_FEE]: "Комиссия с штрафа за просроченый платеж (гарантия выкупа)",
  [TransactionType.GUARANTOR_COMPENSATION_FEE]: "Комиссия с компенсации за досрочное погашение займа (гарантия выкупа)",
  [TransactionType.PROJECT_MANAGEMENT_FEE]: "Комиссия за управление проектом",
  [TransactionType.WITHDRAW_FEE]: "Комиссия за вывод средств",
};
