import {
  PaymentType,
  TransactionType
} from "@/utils/api-client";

export const commonPaymentTransactionTypes = {
  [PaymentType.CREDIT]: [
    TransactionType.DEPOSIT,
  ],
  [PaymentType.DEBIT]: []
};

export const borrowerPaymentTransactionTypes = {
  [PaymentType.CREDIT]: [
    TransactionType.LOAN,
  ],
  [PaymentType.DEBIT]: [
    TransactionType.LOAN_FEE,
    TransactionType.REPAYMENT_PRINCIPAL,
    TransactionType.REPAYMENT_INTEREST,
    TransactionType.REPAYMENT_PENALTY,
    TransactionType.REPAYMENT_COMPENSATION,
  ]
};

export const lenderPaymentTransactionTypes = {
  [PaymentType.CREDIT]: [
    TransactionType.WITHDRAW,
    TransactionType.INVESTMENT_REFUND,
    TransactionType.INVESTMENT_CANCEL,
    TransactionType.DIVIDEND_PRINCIPAL,
    TransactionType.DIVIDEND_INTEREST,
    TransactionType.GUARANTOR_INTEREST,
    TransactionType.DIVIDEND_PENALTY,
    TransactionType.DIVIDEND_COMPENSATION,
    TransactionType.GUARANTOR_COMPENSATION,
    TransactionType.GUARANTOR_PENALTY,
    TransactionType.SALE,
  ],
  [PaymentType.DEBIT]: [
    TransactionType.INVESTMENT,
    TransactionType.PURCHASE,
    TransactionType.SALE_FEE,
    TransactionType.PROJECT_MANAGEMENT_FEE,
    TransactionType.DIVIDEND_INTEREST_FEE,
    TransactionType.DIVIDEND_PENALTY_FEE,
    TransactionType.DIVIDEND_COMPENSATION_FEE,
    TransactionType.GUARANTOR_INTEREST_FEE,
    TransactionType.GUARANTOR_COMPENSATION_FEE,
    TransactionType.GUARANTOR_PENALTY_FEE,
  ]
};
